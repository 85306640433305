import React, { useEffect, useState }  from "react";
import axios from 'axios'
import Badge from 'react-bootstrap/Badge'
import styled from 'styled-components'

const FilterCountBox = styled.div`
  padding-bottom: 15px;
  `;

const FilterCountRow = styled.div`
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  `;

const FilterCountText = styled.div`
  cursor: pointer;
  color: #333;
  margin-bottom: -30px;
  `;

const FilterCountBadge = styled(Badge)`
  cursor: pointer;
  `;


export default function FilterCounts(props) {
  const [values, setValues] = useState({})

  const getValues = async () => {
    const res = await axios.get(props.url)
    let filter_counts = {}
    res.data.forEach(f => {
      filter_counts[f.id] = {name: f.name, item_count: 0}
    })

    props.data.forEach(m => {
      if (m[props.filter_id]) {
        filter_counts[m[props.filter_id]].item_count++
      }
    })
    setValues(filter_counts)
  }

  useEffect(() => {
    getValues()
  }, [props.data])

  const renderRow = (key) => {
    return (
      !props.omit_empty || values[key].item_count > 0
      ? <FilterCountRow key={key} value={key} onClick={() => props.handleFilterChange({"value": key}, props.filter_id, true)}>
          <FilterCountText>{values[key].name}</FilterCountText>
          <h4 className="text-right">
            <FilterCountBadge variant="secondary">{renderCount(key)}</FilterCountBadge>
          </h4>
        </FilterCountRow>
      : ""
    )
  }

  const renderCount = (key) => {
    const filter_count = !props.filters[props.filter_id] || props.filters[props.filter_id] == key ? values[key].item_count : "N/A"
    return filter_count
  }

  return (
    <FilterCountBox>
      <h4>{props.title}</h4>
      { Object.keys(values).map(key =>
          renderRow(key)
        )
      }
    </FilterCountBox>
  );
}
