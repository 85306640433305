import React from 'react'
import SurveyQuestions from './SurveyQuestions'
import SurveyAccordionHeader from './SurveyAccordionHeader'
import { Accordion, Card } from 'react-bootstrap'


export default function SurveyAccordionContent(props) {

  const section = props.section

  return (
    <Card>
      <SurveyAccordionHeader index={props.index} score={section.score ? parseFloat(section.score).toFixed(2) : ""} title={section.title} />
      <Accordion.Collapse eventKey={props.index}>
        <Card.Body>
          <SurveyQuestions questions={section.questions} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>  
  )
}
