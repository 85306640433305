import I18n from 'i18n-js/locales.js'

export const formatValidationErrors = (resourceName, errors) => {
  let errorText = []
  for (const field in errors) {
    errorText = errorText.concat(errors[field].map(e => `${I18n.t(`activerecord.attributes.${resourceName}.${field}`)} ${e}`))
  }
  return errorText.join('<br/>');
}

export const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str)
}