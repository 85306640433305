import React, { Component }  from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectSingle from '../selects/SelectSingle.js'
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'

export default class TPMQuestionForm extends Component {

  state = {
    id: this.props.tpm_question.id || "",
    data_name: this.props.tpm_question.data_name || "",
    answer_type: this.props.tpm_question.answer_type || "string",
    for_analysis: this.props.tpm_question.for_analysis || false,
    data: this.props.tpm_question.data || {},
    alertMessage: "",
  }


  createFormData = () => {
    let formData = new FormData()
    formData.append('tpm_question[data_name]', this.state.data_name)
    formData.append('tpm_question[answer_type]',  this.state.answer_type)
    formData.append('tpm_question[for_analysis]', this.state.for_analysis) 
    formData.append('tpm_question[data]', JSON.stringify(this.state.data))

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const tpm_question = this.createFormData()
    if (this.state.id) {
      axios.put(`${appConstants.TPM_QUESTIONS_URL}/${this.state.id}`, tpm_question )
      .then(res => {
        return window.location.href = appConstants.TPM_QUESTIONS_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('tpm_question', error.response.data) });
      })
    } else {
      axios.post(appConstants.TPM_QUESTIONS_URL, tpm_question )
      .then(res => {
        return window.location.href = appConstants.TPM_QUESTIONS_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('tpm_question', error.response.data) });
      })
    }
  }


  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleDataChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    const newData = {
      ...this.state.data,
      [name]: value,
    }
    this.setState({
      data: newData
    })
  }

  handleSelectChange = (e, selectName) => {
    let selectValue = null
    if (e) { selectValue = e.value } 
    this.setState({
      [selectName]: selectValue
    })
  }

  handleCheckbox = (e) => {
    const target = e.target
    const name = target.name
    const value = !this.state[name]
    this.setState({
      [name]: value
    })
  }
  
  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <Alert variant={'info'} onClose={() => this.setState({ alertMessage: null })} dismissible>
          {this.state.alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  renderData = () => {
    const keys = Object.keys(this.state.data);
    return (
      <Row>
        {keys.map((key,index) =>
          <Form.Group as={Col} md={3} className="mb-3" controlId={key} key={index}>
          <Form.Label>{key}</Form.Label>
          <Form.Control
            key={key}
            name={key}
            type="text"
            value={this.state.data[key] || ""}
            onChange={this.handleDataChange} />
          </Form.Group>
        )}
      </Row>
    )
    
  }


  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={5} className="mb-3" controlId="questionForm.data_name">
          <Form.Label>{I18n.t('activerecord.attributes.tpm_question.data_name')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="data_name"
            type="text"
            value={this.state.data_name}
            onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} md={5} className="mb-3" controlId="questionForm.answerType">
          <Form.Label>{I18n.t('activerecord.attributes.tpm_question.answer_type')}</Form.Label>
            <SelectSingle
                  selected={this.state.answer_type}
                  onValueChange={(e) => this.handleSelectChange(e, "answer_type")}
                  url={appConstants.TPM_ANSWER_TYPES_URL}
                  isClearable={false}
                  placeholder={I18n.t('selects.single')} /> 
          </Form.Group>

          <Form.Group as={Col} md={2} className="mb-3" controlId="questionForm.forAnalysis">
            <Form.Label>{I18n.t('activerecord.attributes.tpm_question.for_analysis')}</Form.Label>
            <Form.Check
              type="checkbox"
              name="for_analysis"
              checked={this.state.for_analysis}
              value={true}
              onChange={this.handleCheckbox}
              bsPrefix="for_analysis-checkbox" />
          </Form.Group>
        </Row>

        {this.renderData()}

        <Form.Group className="mb-3">
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={appConstants.TPM_QUESTIONS_URL}>{I18n.t("navigation.cancel")}</Button>
        </Form.Group>
      </Form>
    );
  }
}
