import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import { validURL } from 'utils/format.js'
import { uploadFile } from 'utils/directUpload.js'
// to use local storage for files, comment out line above, and uncomment the line below
// import { uploadFile } from 'utils/localUpload.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { BiReset } from "react-icons/bi";


class CountyActivityForm extends React.Component {
  state = {
    id: this.props.activity.id || "",
    name: this.props.activity.name || "",
    document: this.props.activity.document || null,
    document_filename: this.props.activity.document_filename ? this.props.activity.document_filename : "",
    document_url: this.props.activity.document_url || "",
    document_blobIdOrFile: this.props.activity.blobSignedId || "",
    ip: this.props.activity.ip || "",
    poc_name: this.props.activity.poc_name || "",
    poc_email: this.props.activity.poc_email || "",
    poc_mobile: this.props.activity.poc_mobile || "",
    alertMessage: "",
    uploading: false,
  }

  createActivityData = async() => {
    let formData = new FormData()
    formData.append('county_activity[name]', this.state.name)
    formData.append('county_activity[ip]', this.state.ip)
    formData.append('county_activity[document_url]', this.state.document_url)
    formData.append('county_activity[poc_name]', this.state.poc_name)
    formData.append('county_activity[poc_email]', this.state.poc_email)
    formData.append('county_activity[poc_mobile]', this.state.poc_mobile)
    formData.append('county_activity[county_profile_id]', this.props.countyProfileId)

    if (!this.state.document_url && this.state.document) {
      if (!this.state.document_blobIdOrFile) {
        const document_blobIdOrFile = await uploadFile(this.state.document)
        this.setState({uploading: true})
        formData.append('county_activity[document]', document_blobIdOrFile)
      }
    }

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    this.createActivityData().then(county_activity => {
      if (this.state.id) {
        axios.put(`${appConstants.COUNTY_ACTIVITIES_URL}/${this.state.id}`, county_activity)
        .then(res => {
          this.setState({uploading: false})
          this.props.closeModal()
        })
        .catch(error => {
          console.log(error)
          this.setState({ alertMessage: formatValidationErrors('county_activity', error.response.data) });
        })
      } else {
        axios.post(`${appConstants.COUNTY_ACTIVITIES_URL}`, county_activity)
        .then(res => {
          this.setState({uploading: false})
          this.props.closeModal()
        })
        .catch(error => {
          console.log(error)
          this.setState({ alertMessage: formatValidationErrors('county_activity', error.response.data) });
        })
      }
    })
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleFileSelect = (e) => {
    this.setState({document_blobIdOrFile: "" })
    this.setState({document_url: "" })
    this.setState({document: e.target.files[0]})
    this.setState({document_filename: e.target.files[0].name})
  }

  handleFileClear = (e) => {
    this.setState({document_blobIdOrFile: "" })
    this.setState({document: null})
    this.setState({document_filename: ""})
  }

  handleURLBlur = (e) => {
    if (!validURL(e.target.value) && !this.state.document) {
      this.setState({
        alertMessage: "Please enter a valid file URL or upload a file"
      });
    } else if (!validURL(e.target.value) && this.state.document) {
      this.setState({ document_url: "" })
    } else {
      this.setState({ alertMessage: "" })
    }
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {this.state.alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  }

  renderSubmitButton() {
    return this.state.uploading ?
    (
      <Button variant="outline-primary" disabled>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
          Uploading...
      </Button>
    )
  : (
      <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button>
    )
  }

  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Form.Row>
          <Form.Group as={Col} md={8}>
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.activity_name')}<span className="text-danger">*</span></Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.ip')}</Form.Label>
            <Form.Control
              name="ip"
              type="text"
              value={this.state.ip}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="countyActivityForm.document">
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.upload_file')}</Form.Label>
            <InputGroup>
              <Form.File id="formcheck-api-custom" 
                disabled={this.state.document_url}
                custom>
                <Form.File.Input 
                  id="document" 
                  onChange={this.handleFileSelect}
                  disabled={this.state.document_url} />
                <Form.File.Label>
                  {this.state.document_filename || I18n.t('resource.upload_file')}
                </Form.File.Label>
              </Form.File>
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={this.handleFileClear}><BiReset /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="countyActivityForm.document_url">
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.provide_url')}<span className="text-danger">*</span></Form.Label>
            <Form.Control 
              name="document_url"
              type="text"
              value={this.state.document_url}
              onChange={this.handleInputChange}
              onBlur={this.handleURLBlur}
              disabled={this.state.document} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.poc')}</Form.Label>
            <Form.Control
              name="poc_name"
              type="text"
              value={this.state.poc_name}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.poc_email')}</Form.Label>
            <Form.Control
              type="text"
              name="poc_email"
              value={this.state.poc_email}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_activity.poc_mobile')}</Form.Label>
            <Form.Control
              type="text"
              name="poc_mobile"
              value={this.state.poc_mobile}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          {this.renderSubmitButton()}&nbsp;
          <Button variant="outline-secondary" href={this.props.isModal ? "#" : appConstants.COUNTY_PROFILES_URL} onClick={this.props.closeModal}>{I18n.t('navigation.cancel')}</Button>
        </Form.Group>
      </Form>
    );
  }
}

export default CountyActivityForm