import React from 'react'
import SurveyQuestions from './SurveyQuestions'
import { Card } from 'react-bootstrap'


export default function SurveySubQuestions(props) {

  const dependent = props.dependent

  if (!dependent || dependent.show_if != props.answer) {
    return null
  }

  return (
    <Card>
      <Card.Header>{dependent.title}</Card.Header>
      <Card.Body>
      <SurveyQuestions questions={dependent.questions} />
      </Card.Body>
    </Card>
  )
}
