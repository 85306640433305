import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import I18n from 'i18n-js/locales.js'
import SelectSingle from '../selects/SelectSingle.js'
import { appConstants } from 'utils/constants.js'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function MonitoringRequestFilters(props) {

  const renderActivity = () => {
    return (
      "activity_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="activity">
            <SelectSingle
              selected={parseInt(props.includedFilters["activity_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "activity_id")}
              url={appConstants.ACTIVITIES_URL + ".json"}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.activity')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderIP = () => {
    return (
      "implementing_partner_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="implementing_partner">
            <SelectSingle
              selected={parseInt(props.includedFilters["implementing_partner_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "implementing_partner_id")}
              url={appConstants.IPS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.implementing_partner')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderGovernorate = () => {
    return (
      "governorate_id" in props.includedFilters
      ? <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="governorate">
            <SelectSingle
              selected={parseInt(props.includedFilters["governorate_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "governorate_id")}
              url={props.page && props.page === "tpm" ? appConstants.GOVERNORATES_WITH_TPM_DATA_URL : appConstants.GOVERNORATES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.governorate')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderDistrict = () => {
    let url = appConstants.DISTRICTS_URL
    if (props.page === "cm") {
      url = appConstants.DISTRICTS_WITH_CM_DATA_URL
    }

    if (props.page === "tpm") {
      url = appConstants.DISTRICTS_WITH_TPM_DATA_URL
    }

    return (
      "district_id" in props.includedFilters
      ? <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="district">
            <SelectSingle
              selected={parseInt(props.includedFilters["district_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "district_id")}
              url={url}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.district')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMonitor = () => {
    return (
      "user_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="user">
            <SelectSingle
              selected={parseInt(props.includedFilters["user_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "user_id")}
              url={appConstants.MONITORS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.user')} 
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderReviewer = () => {
    return (
      "reviewer_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="reviewer">
            <SelectSingle
              selected={parseInt(props.includedFilters["reviewer_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "reviewer_id")}
              url={appConstants.REVIEWERS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.reviewer')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderStartWeek = () => {
    return (
      "start_week" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="start_week">
            <SelectSingle
              selected={props.includedFilters["start_week"]}
              onValueChange={(e) => props.handleFilterChange(e, "start_week")}
              url={appConstants.TIME_INTERVALS_URL}
              placeholder={I18n.t('selects.week')}
              isClearable={true}
              isFilter={true}  />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderTimeInterval = () => {
    return (
      "time_interval_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="time_interval">
            <SelectSingle
              selected={props.includedFilters["time_interval_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "time_interval_id")}
              url={appConstants.TIME_INTERVALS_URL}
              placeholder={I18n.t('selects.time_period')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderTimeSlider = () => {
    const createSliderWithTooltip = Slider.createSliderWithTooltip;
    const Range = createSliderWithTooltip(Slider.Range);
    return (
      "days_range" in props.includedFilters
      ? <Col md={2} className="p-2 ml-3 mr-5">
        <Form.Group controlId="days_back">
          <p>{I18n.t('selects.days_back')}</p>
          <Range
            defaultValue={props.includedFilters["days_range"] || [0,30]}
            onAfterChange={(e) => props.handleFilterChange({value: e, label: e}, "days_range")}
            reverse={true}
            max={90}
            trackStyle={[{backgroundColor: '#4772A5'}]}
            handleStyle={[{
              borderColor: '#4772A5',
              borderWidth: '3px',
              backgroundColor: '#FFFFFF',
            },{
              borderColor: '#4772A5',
              borderWidth: '3px',
              backgroundColor: '#FFFFFF',
            }]} />
        </Form.Group>
      </Col>
      : ""
    )
  }

  const renderTotalScore = () => {
    return (
      "total_score" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="total_score">
            <SelectSingle
              selected={props.includedFilters["total_score"]}
              onValueChange={(e) => props.handleFilterChange(e, "total_score")}
              url={appConstants.TOTAL_SCORES_URL}
              placeholder={I18n.t('selects.total_score')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderStatus = () => {
    return (
      "request_status_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="request_status">
            <SelectSingle
              selected={props.includedFilters["request_status_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "request_status_id")}
              url={appConstants.EVENT_STATUSES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.top_level_status')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMode = () => {
    return (
      "virtual" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="virtual">
            <SelectSingle
              selected={props.includedFilters["virtual"]}
              onValueChange={(e) => props.handleFilterChange(e, "virtual")}
              url={appConstants.EVENT_MODES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.mode')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSector = () => {
    return (
      "sector_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="sector">
            <SelectSingle
              selected={props.includedFilters["sector_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "sector_id")}
              url={appConstants.SECTORS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.sector')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderType = () => {
    return (
      "request_type" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="request_type">
            <SelectSingle
              selected={props.includedFilters["request_type"]}
              onValueChange={(e) => props.handleFilterChange(e, "request_type")}
              url={appConstants.REQUEST_TYPES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.request_type')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderOffice = () => {
    return (
      "office_id" in props.includedFilters
      ?  <Col md={2} className="p-2 ml-3 mr-3">
          <Form.Group controlId="office">
            <SelectSingle
              selected={parseInt(props.includedFilters["office_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "office_id")}
              url={appConstants.OFFICES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.office')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSearch = () => {
    return (
      !props.excludeSearch
      ?   
            <Col md={2} className="p-2 ml-3 mr-3">
              <Form.Control type="text" placeholder={I18n.t("headers.monitoring_request.search")} onChange={(e) => props.handleSearch(e)} />
            </Col>
          
      : ""
    )
  }


  return (
      <Form  className="border rounded bg-light mb-2">
        <Row >
          {renderGovernorate()}
          {renderDistrict()}
          {renderActivity()}
          {renderIP()}
          {renderMonitor()}
          {renderReviewer()}
          {renderStartWeek()}
          {renderTimeInterval()}
          {renderTotalScore()}
          {renderSector()}
          {renderOffice()}
          {renderMode()}
          {renderStatus()}
          {renderType()}
          {renderTimeSlider()}
          {renderSearch()}
        </Row>
        
      </Form>
  )
}
