import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import SelectSingle from '../selects/SelectSingle.js'
import { appConstants } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

export default function FlagForm(props) {
  const [id, setId] = useState(props.flag.id || "")
  const [name, setName] = useState(props.flag.name || "")
  const [description, setDescription] = useState(props.flag.description || "")
  const [monitoringRequestId, setMonitoringRequestId] = useState(props.flag.monitoring_request_id || "")
  const [monitoringRequest, setMonitoringRequest] = useState({})
  const [dateEntered, setDateEntered] = useState(props.flag.date_entered ? moment(props.flag.date_entered) : moment(Date.now()))
  const [reportUploadDate, setReportUploadDate] = useState(null)
  const [alertMessage, setAlertMessage] = useState("")
  const [updated, setUpdated] = useState(false)
  const [deleted, setDeleted] = useState(false)

  useEffect(() => {
    if (monitoringRequestId) {
      const loadMonitoringRequestData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}${monitoringRequestId}.json`)
        setMonitoringRequest(response.data || {})
        setReportUploadDate((response.data && response.data.get_report_upload_date) ? moment(response.data.get_report_upload_date) : null)
        props.setFlagTitle((response.data && response.data.name) ? response.data.name : "")
      }
      loadMonitoringRequestData()
    }
  }, [monitoringRequestId])

  useEffect(() => {
    if (updated) {
      props.refreshData()
      setUpdated(false)
    }
    if (deleted) {
      props.refreshData()
      setDeleted(false)
      props.closeModal()
    }
  }, [updated, deleted])


  const createFlagData = () => {
    let formData = new FormData()
    formData.append('flag[name]', name)
    formData.append('flag[description]', description)
    formData.append('flag[monitoring_request_id]', monitoringRequestId)

    return formData
  }


  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const flag = createFlagData()
    const successNotice = id ? I18n.t("notices.update_success") : I18n.t("notices.create_success")
    const res = id ? await axios.put(`${appConstants.FLAGS_URL}/${id}`, flag ) : await axios.post(appConstants.FLAGS_URL, flag )
    const notice = res.status === 200 ? successNotice : I18n.t("notices.failure")
    setAlertMessage(notice)
    setUpdated(true)
  }

  const handleDelete = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.delete(`${appConstants.FLAGS_URL}/${id}.json`)
    const notice = res.status === 204 ? I18n.t("notices.delete_success") : I18n.t("notices.delete_failure")
    setAlertMessage(notice)
    setDeleted(true)
  }

  const handleAlertMessage = () => {
    if (alertMessage){
      return (
        <Alert variant={'info'} onClose={() => setAlertMessage(null)} dismissible>
          {alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  const renderDeleteButton = () => {
    if (id && props.canEdit) {
      return (<Button variant="outline-danger" style={{float: 'right'}} onClick={handleDelete}>{I18n.t("navigation.delete")}</Button>)
    }
  }

  const renderAddUpdateButton = () => {
    if (props.canEdit) {
      return (<Button variant="outline-primary" onClick={handleSubmit}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button>)
    }
  }

  const renderReportButton = () => {
    if (monitoringRequest.get_report_url) {
      return (<Button variant="outline-secondary" size="lg" href={monitoringRequest.get_report_url} target="_blank" className="mt-4">View Report</Button> )
    }
  }

  return (
    <Form>
      <Row>
        <Col>
          {handleAlertMessage()}
        </Col>
      </Row>

      <Form.Row>
        <Form.Group as={Col} md={9} controlId="flagForm.name">
          <Form.Label>{I18n.t('activerecord.attributes.flag.name')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e ? e.target.value : "")} />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>{I18n.t('activerecord.attributes.flag.date_entered')}</Form.Label><br/>
          <SingleDatePicker
            disabled={true}
            date={dateEntered}
            onDateChange={() => {}}
            onFocusChange={() => {}}
            id="date_entered"
            isOutsideRange={()=>false} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_event')}<span className="text-danger">*</span></Form.Label>
            <SelectSingle
              selected={monitoringRequestId}
              onValueChange={(e) => setMonitoringRequestId(e ? e.value : "")}
              url={appConstants.FLAGGABLE_REQUESTS_URL + "?cm=true"}
              isClearable={false}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.start_date')}</Form.Label><br/>
          <SingleDatePicker
            disabled={true}
            date={monitoringRequest.start_date ? moment(monitoringRequest.start_date) : null}
            onDateChange={() => {}}
            onFocusChange={() => {}}
            id="visit_date"
            isOutsideRange={()=>false} />
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>{I18n.t('activerecord.attributes.flag.report_upload_date')}</Form.Label><br/>
          <SingleDatePicker
            disabled={true}
            date={reportUploadDate}
            onDateChange={() => {}}
            onFocusChange={() => {}}
            id="date_entered"
            isOutsideRange={()=>false} />
        </Form.Group>
        <Form.Group as={Col} md={2} controlId="flagForm.report_link" className="pt-2">
          {renderReportButton()}
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request_ip">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
            <SelectSingle
              selected={monitoringRequest.activity_id}
              onValueChange={() => {}}
              url={appConstants.ACTIVITIES_URL + '.json'}
              isClearable={false}
              placeholder={'-'}
              isDisabled={true} />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.implementing_partner')}</Form.Label>
            <SelectSingle
              selected={monitoringRequest.implementing_partner_id}
              onValueChange={() => {}}
              url={appConstants.IMPLEMENTING_PARTNERS_URL + '.json'}
              isClearable={false}
              placeholder={'-'}
              isDisabled={true} />
          </Form.Group>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request_state">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.governorate')}</Form.Label>
            <SelectSingle
              selected={monitoringRequest.governorate ? monitoringRequest.governorate.id : ""}
              onValueChange={() => {}}
              url={appConstants.GOVERNORATES_URL}
              isClearable={false}
              placeholder={'-'}
              isDisabled={true} />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request_county">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.district')}</Form.Label>
            <SelectSingle
              selected={monitoringRequest.district_id}
              onValueChange={() => {}}
              url={appConstants.DISTRICTS_URL}
              isClearable={false}
              placeholder={'-'}
              isDisabled={true} />
          </Form.Group>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request_payam">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.territory')}</Form.Label>
            <SelectSingle
              selected={monitoringRequest.territory_id}
              onValueChange={() => {}}
              url={appConstants.TERRITORIES_URL}
              isClearable={false}
              placeholder={'-'}
              isDisabled={true} />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Group controlId="flagForm.monitoring_request_site">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.zone')}</Form.Label>
            <SelectSingle
              selected={monitoringRequest.zone_id}
              onValueChange={() => {}}
              url={appConstants.ZONES_URL}
              isClearable={false}
              placeholder={'-'}
              isDisabled={true} />
          </Form.Group>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={6} className="mb-3">
          <Form.Group className="mb-3" controlId="flagForm.description">
            <Form.Label>{I18n.t('activerecord.attributes.flag.description')}</Form.Label>
            <Form.Control
              name="description"
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4" />
          </Form.Group>
        </Form.Group>
      </Form.Row>

      <Form.Group controlId="flagForm.buttons">
        {renderAddUpdateButton()} &nbsp;
        {renderDeleteButton()}
      </Form.Group>
    </Form>
  );
}