import React from 'react'
import styled from 'styled-components';
import { Accordion, Card, Button } from 'react-bootstrap'

const ScoredHeader = styled(Card.Header)`
  background-color: ${props =>
    (parseFloat(props.score) > .74)
      ? 'rgba(75, 192, 192, 0.2)'
      : (parseFloat(props.score) > .49)
      ? 'rgba(255, 206, 86, 0.2)'
      : (!isNaN(props.score))
      ? 'rgba(255, 99, 132, 0.2)'
      : ''}
`;


export default function SurveyAccordionHeader(props) {

  const title = props.title
  const score =  props.score ? "(" + String(props.score) + ")" : ""

  return (
    <ScoredHeader score={props.score}>
      <Accordion.Toggle as={Button} variant="link" eventKey={props.index}>
      {title} {score}
      </Accordion.Toggle>
    </ScoredHeader>
  )
}
