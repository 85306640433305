import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectSingle from '../selects/SelectSingle.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

class CountyContactForm extends React.Component {
  state = {
    id: this.props.contact.id || "",
    name: this.props.contact.name || "",
    title: this.props.contact.title || "",
    organization: this.props.contact.organization || "",
    info: this.props.contact.info || "",
    contact_type_id: this.props.contact.contact_type_id || "",
    contact_type_name: this.props.contact.contact_type_name || "",
    county_profile_id: this.props.countyProfileId || "",
    alertMessage: "",
  }

  handleSubmit = () => {
    const county_contact = this.state
    delete county_contact.alertMessage
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    if (this.state.id) {
      axios.put(`${appConstants.COUNTY_CONTACTS_URL}/${county_contact.id}`, county_contact)
      .then(res => {
        this.props.closeModal()
      })
      .catch(error => {
        console.log(error)
        this.setState({ alertMessage: formatValidationErrors('county_contact', error.response.data) });
      })
    } else {
      axios.post(`${appConstants.COUNTY_CONTACTS_URL}`, county_contact)
      .then(res => {
        this.props.closeModal()
      })
      .catch(error => {
        console.log(error)
        this.setState({ alertMessage: formatValidationErrors('county_contact', error.response.data) });
      })
    }
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleSelectChange = (e, selectName) => {
    let selectValue = null
    if (e) { selectValue = e.value } 
    this.setState({
      [selectName]: selectValue
    })
  }
  
  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {this.state.alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  }

  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Form.Row>
          <Form.Group as={Col} md={8}>
            <Form.Label>{I18n.t('activerecord.attributes.county_contact.name')}<span className="text-danger">*</span></Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_contact.type')}<span className="text-danger">*</span></Form.Label>
            <SelectSingle
              selected={this.state.contact_type_id}
              onValueChange={(e) => this.handleSelectChange(e, "contact_type_id")}
              url={`${appConstants.CONTACT_TYPES_URL}.json`}
              isClearable={false}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_contact.title')}</Form.Label>
            <Form.Control
              name="title"
              type="text"
              value={this.state.title}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_contact.institution')}</Form.Label>
            <Form.Control
              type="text"
              name="organization"
              value={this.state.organization}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>{I18n.t('activerecord.attributes.county_contact.info')}</Form.Label>
            <Form.Control
              type="text"
              name="info"
              value={this.state.info}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={this.props.isModal ? "#" : appConstants.COUNTY_PROFILES_URL} onClick={this.props.closeModal}>{I18n.t('navigation.cancel')}</Button>
        </Form.Group>
      </Form>
    );
  }
}

export default CountyContactForm