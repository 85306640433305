import React from "react"
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

export default function SelectFiscalYear(props) {
  const now = new Date().getUTCFullYear()
  const years = Array(now - (now - 10)).fill('').map((v, idx) => now - idx);
  const months = [
    {id: 0, name: "January"},
    {id: 1, name: "February"},
    {id: 2, name: "March"},
    {id: 3, name: "April"},
    {id: 4, name: "May"},
    {id: 5, name: "June"},
    {id: 6, name: "July"},
    {id: 7, name: "August"},
    {id: 8, name: "September"},
    {id: 9, name: "October"},
    {id: 10, name: "November"},
    {id: 11, name: "December"}
  ]

  return (
    <Form.Row>
      <Form.Group as={Col} controlId="resourceForm.month">
        <Form.Control name="fyMonth" value={props.fyDate.getMonth()} onChange={props.onChange} as="select" >
          {months.map(m => <option value={m.id} key={m.name}>{m.name}</option>)}
        </Form.Control>
      </Form.Group>

      <Form.Group as={Col} controlId="resourceForm.year">
        <Form.Control name="fyYear" value={props.fyDate.getUTCFullYear()} onChange={props.onChange} as="select" >
          {years.map(y => <option value={y} key={y}>{y}</option>)}
        </Form.Control>
      </Form.Group>
    </Form.Row>
  )
}