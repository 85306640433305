import React from 'react'
import { Col } from 'react-bootstrap'
import { Pie } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'


export default function ThematicChart(props) {

  const label = props.label
  const data = props.data
  const chartType = props.chartType
  const columns = props.columns

  const chartData = {
    labels: data.map((c) => c.answer),
    datasets: [
      {
        label: label,
        data: data.map((c) => c.count),
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Col md={columns}>
      <h6>{label}</h6>
      {chartType == "bar" ? <Bar data={chartData} /> : <Pie data={chartData} /> }
    </Col>
  )
}
