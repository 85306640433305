import React from 'react'
import I18n from 'i18n-js/locales.js'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup';

export default function CountyProfileGeneralInfo(props) {
  return (
    <ListGroup variant="flush">
      <div className="split-items">
        <span className="list-group-item left-side"><Image className="county-profile-icon" src={require("../../assets/images/Food-Security.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.food_security')}</span></span>
        <span className="list-group-item right-side"><a href={props.foodSecurityReportUrl}>{props.foodSecurityReportUrl}</a></span>
      </div>
      <div className="split-items">
        <span className="list-group-item left-side"><Image className="county-profile-icon" src={require("../../assets/images/Financing.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.livelihoods')}</span></span>
        <span className="list-group-item right-side">{props.livelihoods}</span>
      </div>
      <div className="split-items">
        <span className="list-group-item left-side"><Image className="county-profile-icon" src={require("../../assets/images/Government-office.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.governance')}</span></span>
        <span className="list-group-item right-side">{props.governance}</span>
      </div>
      <div className="split-items">
        <span className="list-group-item left-side"><Image className="county-profile-icon" src={require("../../assets/images/Car.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.roads')}</span></span>
        <span className="list-group-item right-side">{props.roads}</span>
      </div>
      <div className="split-items">
        <span className="list-group-item left-side"><Image className="county-profile-icon" src={require("../../assets/images/Cell-tower.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.network')}</span></span>
        <span className="list-group-item right-side">{props.network}</span>
      </div>
      <div className="split-items">
        <span className="list-group-item left-side"><Image className="county-profile-icon" src={require("../../assets/images/Airport.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.airport')}</span></span>
        <span className="list-group-item right-side">{props.airport}</span>
      </div>
    </ListGroup>
  );
}