import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import moment from 'moment'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import axios from 'axios'

export default function MonitoringRequestInfo(props) {
  const [request, setRequest] = useState([])

  const loadRequest = async() => {
    const res = await axios.get(`${appConstants.MONITORING_EVENT_URL}${props.monitoringRequestId}.json`)
    setRequest(res.data)
  }

  useEffect(() => {
    loadRequest()
  }, [])

  return (
    <Container>
      <Row>
        <Col md={10}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.name')}: </strong>
          {request.name}</p>
        </Col>
        <Col md={2}>
          <p><Badge pill variant={request.request_type == "cm" ? "warning" : "info"}>{request.request_type ? request.request_type.toString().toUpperCase() : ""}</Badge></p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.activity')}: </strong>
          {request.activity ? request.activity.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.implementing_partner')}: </strong>
          {request.implementing_partner ? request.implementing_partner.name : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.governorate')}: </strong>
          {request.governorate ? request.governorate.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.district')}: </strong>
          {request.district ? request.district.name : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.territory')}: </strong>
          {request.territory ? request.territory.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.office')}: </strong>
          {request.office ? request.office.name : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.sector')}: </strong>
          {request.sector ? request.sector.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.subsectors')}: </strong>
          {request.subsectors ? request.subsectors.map((s, i) => <span className="badge badge-secondary" key={i}>{s.name}</span>) : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.resource.tags')}: </strong>
          {request.tags ? request.tags.map((t, i) => <span className="badge badge-secondary" key={i}>{t.name}</span>) : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.start_date')}: </strong>
          {request.start_date ? moment(request.start_date).format('MM-DD-YYYY') : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.end_date')}: </strong>
          {request.end_date ? moment(request.end_date).format('MM-DD-YYYY') : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.date_submitted')}: </strong>
          {request.date_submitted ? moment(request.date_submitted).format('MM-DD-YYYY') : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.request_justification')}: </strong>
          {request.request_justification}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.request_justification_details')}: </strong>
          {request.data ? request.data.request_justification_details : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.latitude')}: </strong>
          {request.latitude}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.longitude')}: </strong>
          {request.longitude}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.description')}: </strong>
          {request.data ? request.data.description : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.security_hold')}: </strong>
          {request.hold ? 'Yes' : 'No'}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.poc')}: </strong>
          {request.data && request.data.poc ? request.data.poc : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.notes')}: </strong>
          {request.data && request.data.notes ? request.data.notes : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.virtual')}: </strong>
          {request.virtual ? 'Yes' : 'No'}</p>
        </Col>
        <Col md={4}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.mode_of_transport')}: </strong>
          {request.data && request.data.mode_of_transport ? request.data.mode_of_transport : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.request_status')}: </strong>
          {I18n.t(`activerecord.attributes.monitoring_request/request_status.${request.request_status}`)}</p>
        </Col>
      </Row>
    </Container>
  )
}
