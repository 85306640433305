import React from 'react'
import SurveySubQuestions from './SurveySubQuestions'
import { Card } from 'react-bootstrap'


export default function SurveyQuestions(props) {

  const questions = props.questions

  return (
    <>
      {questions.map(
          (question, index) => 
            <Card key={index}>
              <Card.Header>{question.text}</Card.Header>
              <Card.Body>
                {question.answer}
                <SurveySubQuestions dependent={question.dependent} answer={question.answer} />
              </Card.Body>
            </Card>
      )} 
    </>  
  )
}
