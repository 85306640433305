import React from 'react'
import SurveyAccordionContent from './SurveyAccordionContent'
import { Accordion } from 'react-bootstrap'


export default function SurveyAccordion(props) {

  const sections = props.sections
  const sectionKeys = props.sectionOrder

  return (
    <Accordion>
      {sectionKeys.map((section, index) => <SurveyAccordionContent key={index} section={sections[section]} index={index+1} />)}            
    </Accordion>
  )
}
