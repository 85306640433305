import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { useReactToPrint } from 'react-to-print';
import { appConstants } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import SelectSingle from './selects/SelectSingle.js'
import CountyProfile from './CountyProfile'
import CountyProfileForm from './forms/CountyProfileForm'


export default function CountyProfilesPage(props) {
  const componentRef = useRef();

  const [counties, setCounties] = useState(props.counties || [])
  const [selectedProfileId, setSelectedProfileId] = useState(props.counties[0].id || "")
  const [selectedProfile, setSelectedProfile] = useState({})
  const [contacts, setContacts] = useState([])
  const [activities, setActivities] = useState([])
  const [pageVariant, setPageVariant] = useState('show')

  useEffect(() => {
    loadProfileActivities();
    loadProfileContacts();
  }, [selectedProfile])

  useEffect(() => {
    loadCounties();
    loadProfile();
  }, [pageVariant, selectedProfileId])

  const loadCounties = async() => {
    const res = await axios.get(`${appConstants.COUNTIES_URL}`)
    setCounties(res.data)
  }

  const loadProfile = async() => {
    const res = await axios.get(`${appConstants.COUNTY_PROFILES_URL}/${selectedProfileId}.json`)
    setSelectedProfile(res.data)
  }

  const loadProfileActivities = async() => {
    const res = await axios.get(`${appConstants.COUNTY_PROFILES_URL}/${selectedProfileId}/activities.json`)
    setActivities(res.data)
  }

  const loadProfileContacts = async() => {
    const res = await axios.get(`${appConstants.COUNTY_PROFILES_URL}/${selectedProfileId}/contacts.json`)
    setContacts(res.data)
  }

  const setPage = (variant, profileId) => {
    setPageVariant(variant)
    if (profileId) { setSelectedProfileId(profileId) }
    if (variant == 'new') { setActivities([]), setContacts([]) }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const handleCountySelect = (e) => {
    setSelectedProfileId(e.value)
  }

  const renderCountySelect = () => {
    return (
      <Col md={3} className="p-2 ml-3 mr-3">
        <SelectSingle
          selected={parseInt(selectedProfile.id)}
          onValueChange={(e) => handleCountySelect(e)}
          url={appConstants.COUNTIES_URL}
          placeholder={I18n.t('activerecord.attributes.monitoring_request.district')} />
      </Col>
    )
  }

  const renderAdminButtons = () => {
    return (props.userRole == 'admin') ?
    (
      <>
        <Button onClick={(v) => setPage('new')} variant="primary" className="county-profile-btn">{I18n.t('headers.county_profile.new')}</Button>&nbsp;
        <Button onClick={(v) => setPage('edit')} variant="secondary" className="county-profile-btn">{I18n.t('headers.county_profile.edit')}</Button>&nbsp;
      </>
    ) : ""
  }

  const renderActionBar = () => {
    return (
      <Row className="border rounded bg-light p-2 my-2 mx-3 pr-4 county-profile-action-bar">
        {renderCountySelect()}
        <div className="col-md-3 p-2">
          {renderAdminButtons()}
          <Button onClick={handlePrint} variant="outline-primary" className="county-profile-btn">{I18n.t('headers.county_profile.print')}</Button>
        </div>
      </Row>
    )
  }

  const renderForm = () => {
    return (
      <CountyProfileForm profile={pageVariant === 'edit' ? selectedProfile : {}} contacts={contacts} activities={activities} setPage={setPage} authenticityToken={props.authenticityToken} />
    )
  }

  const renderPage = () => {
    return (
      <div>
        <div>
          {renderActionBar()}
        </div>
        <CountyProfile ref={componentRef} profile={selectedProfile} contacts={contacts} activities={activities} />
      </div>
    )
  }

  return (
    <div>
      {props.userRole == 'admin' && pageVariant !== 'show' ? renderForm() : renderPage()}
    </div>
  );
};