import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import ThematicQuestionGroup from '../details/ThematicQuestionGroup'


export default function ThematicAccordionContent(props) {

  const section = props.section
  const questions = section.questions

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={props.index}>
        {section.name} 
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.index}>
        <Card.Body>
          <ThematicQuestionGroup questions={questions} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>  
  )
}
