import React from 'react'
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

export default function Board(props) {
  const minHeight = props.minHeight || '540px';
  return (
    <DragDropContext 
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
    >
      <Container fluid>
        <Row>
          {props.initialData.columnOrder.map((columnId, index) => {
            const column = props.initialData.columns[columnId]
            const tasks = column.taskIds.map(taskId => props.initialData.tasks[taskId])

            const isDropDisabled = props.dropDisabled(index, columnId, props.initialData.homeIndex, props.initialData.multidirectional)

            return <Column key={column.id} column={column} tasks={tasks} isDropDisabled={isDropDisabled} taskContent={props.taskContent} minHeight={minHeight} />
          })}
        </Row>
      </Container>
    </DragDropContext>
  )
}
