import React from 'react'
import I18n from 'i18n-js/locales.js'
import Table from 'react-bootstrap/Table';

export default function CountyProfileActivities(props) {
  const renderActivityRow = (a, index) => {
    return (
      <tr key={index}>
        <td><a href={a["document_uploaded_url"] ? a["document_uploaded_url"] : a["document_url"]} target="_blank">{a["name"]}</a></td>
        <td>{a["ip"]}</td>
        <td>{a["poc_name"]}</td>
        <td>{a["poc_email"]}</td>
        <td>{a["poc_mobile"]}</td>
      </tr>
    )
  }

  return (
    <div className=""><br/>
      <h2 className="county-profile-header-text">{I18n.t('activerecord.attributes.county_profile.activities_and_ips')}</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{I18n.t('activerecord.attributes.county_activity.activity')}</th>
            <th>{I18n.t('activerecord.attributes.county_activity.ip')}</th>
            <th>{I18n.t('activerecord.attributes.county_activity.poc')}</th>
            <th>{I18n.t('activerecord.attributes.county_activity.email')}</th>
            <th>{I18n.t('activerecord.attributes.county_activity.mobile')}</th>
          </tr>
        </thead>
        <tbody>
          {props.activities.map((a,i) => renderActivityRow(a, i))}
        </tbody>
      </Table>
    </div>
  )
}