import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import moment from 'moment'
import { formatValidationErrors, validURL } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import { uploadFile } from 'utils/directUpload.js'
// to use local storage for files, comment out line above, and uncomment the line below
// import { uploadFile } from 'utils/localUpload.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import { BiReset, BiPlus, BiPencil, BiTrash } from "react-icons/bi";
import { SingleDatePicker } from 'react-dates'

import CountyContactForm from './CountyContactForm.js'
import CountyActivityForm from './CountyActivityForm.js'

class CountyProfileForm extends React.Component {

  state = {
    id: this.props.profile.id || "",
    countyName: this.props.profile.county_name || "",
    population: this.props.profile.population || 0,
    peopleInNeed: this.props.profile.people_in_need || 0,
    foodSecurityPercentage: this.props.profile.food_security_percentage || 0,
    displaced: this.props.profile.displaced || 0,
    returnees: this.props.profile.returnees || 0,
    ethnicities: this.props.profile.ethnicities || "",
    livelihoods: this.props.profile.livelihoods || "",
    governance: this.props.profile.governance || "",
    roads: this.props.profile.roads || "",
    network: this.props.profile.network || "",
    airport: this.props.profile.airport || "",
    lastFlyAway: this.props.profile.last_fly_away ? moment(this.props.profile.lastFlyAway) : null,
    foodSecurityReportUrl: this.props.profile.food_security_report_url || "",
    plsoReportUrl: this.props.profile.plso_report_url || "",
    plsoReportFile: this.props.profile.plso_report_file || null,
    plsoReportFilename: this.props.profile.plso_report_filename ? this.props.profile.plso_report_filename : "",
    plsoReportBlobIdOrFile: this.props.profile.plso_report_blobSignedId || "",
    countyMapUrl: this.props.profile.county_map_url || "",
    countyMapFile: this.props.profile.county_map_file || null,
    countyMapFilename: this.props.profile.county_map_filename ? this.props.profile.county_map_filename : "",
    countyMapBlobIdOrFile: this.props.profile.county_map_blobSignedId || "",
    gisMapUrl: this.props.profile.gis_map_url || "",
    locationMapUrl: this.props.profile.location_map_url || "",
    locationMapFile: this.props.profile.location_map_file || null,
    locationMapFilename: this.props.profile.location_map_filename ? this.props.profile.location_map_filename : "",
    locationMapBlobIdOrFile: this.props.profile.location_map_blobSignedId || "",
    contacts: this.props.contacts ? this.props.contacts : [],
    activities: this.props.activities ? this.props.activities : [],
    alertMessage: "",
    showContactModal: false,
    showActivityModal: false,
    lastFlyAwayFocused: false,
    modalContact: {countyProfileId: this.props.profile.id},
    modalActivity: {countyProfileId: this.props.profile.id},
  }

  // Form action handlers
  createProfileData = async function() {
    let formData = new FormData()
    formData.append('county_profile[county_name]', this.state.countyName)
    formData.append('county_profile[population]', this.state.population)
    formData.append('county_profile[people_in_need]', this.state.peopleInNeed)
    formData.append('county_profile[food_security_percentage]', this.state.foodSecurityPercentage)
    formData.append('county_profile[displaced]', this.state.displaced)
    formData.append('county_profile[returnees]', this.state.returnees)
    formData.append('county_profile[ethnicities]', this.state.ethnicities)
    formData.append('county_profile[livelihoods]', this.state.livelihoods)
    formData.append('county_profile[governance]', this.state.governance)
    formData.append('county_profile[roads]', this.state.roads)
    formData.append('county_profile[network]', this.state.network)
    formData.append('county_profile[airport]', this.state.airport)
    formData.append('county_profile[last_fly_away]', this.state.lastFlyAway)
    formData.append('county_profile[food_security_report_url]', this.state.foodSecurityReportUrl)
    formData.append('county_profile[plso_report_url]', this.state.plsoReportUrl)
    formData.append('county_profile[county_map_url]', this.state.countyMapUrl)
    formData.append('county_profile[location_map_url]', this.state.locationMapUrl)
    formData.append('county_profile[gis_map_url]', this.state.gisMapUrl)

    if (!this.state.plsoReportUrl && this.state.plsoReportFile) {
      if (!this.state.plsoReportBlobIdOrFile) {
        const plsoReportBlobIdOrFile = await uploadFile(this.state.plsoReportFile)
        this.setState({ plsoReportBlobIdOrFile: plsoReportBlobIdOrFile });
      }
      formData.append('county_profile[plso_report_file]', this.state.plsoReportBlobIdOrFile)
    }

    if (!this.state.countyMapUrl && this.state.countyMapFile) {
      if (!this.state.countyMapBlobIdOrFile) {
        const countyMapBlobIdOrFile = await uploadFile(this.state.countyMapFile)
        this.setState({ countyMapBlobIdOrFile: countyMapBlobIdOrFile });
      }
      formData.append('county_profile[county_map_file]', this.state.countyMapBlobIdOrFile)
    }

    if (!this.state.locationMapUrl && this.state.locationMapFile) {
      if (!this.state.locationMapBlobIdOrFile) {
        const locationMapBlobIdOrFile = await uploadFile(this.state.locationMapFile)
        this.setState({ locationMapBlobIdOrFile: locationMapBlobIdOrFile });
      }
      formData.append('county_profile[location_map_file]', this.state.locationMapBlobIdOrFile)
    }

    return formData
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.countyName.length > 0) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
      if (this.state.id) {
        this.createProfileData().then((profile) => {
          axios.put(`${appConstants.COUNTY_PROFILES_URL}/${this.state.id}`, profile)
          .then(res => {
            this.submitActivities(this.state.id);
            this.props.setPage('show', res.data.id);
          })
          .catch(error => {
            console.log(error)
            this.setState({ alertMessage: formatValidationErrors('county_profile', error.response.data) });
          })
        })
      } else {
        this.createProfileData().then((profile) => {
          axios.post(`${appConstants.COUNTY_PROFILES_URL}`, profile)
          .then(res => {
            this.submitActivities(res.data.id);
            this.props.setPage('show', res.data.id)
          })
          .catch(error => {
            console.log(error);
            this.setState({ alertMessage: formatValidationErrors('county_profile', error.response.data) });
          })
        })
      }
    } else {
      this.setState({ alertMessage: I18n.t('activerecord.attributes.county_profile.county_save_validation') })
    }
  }

  handleDelete = () => {
    if ( confirm(I18n.t('activerecord.attributes.county_profile.confirm_deletion')) ) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
      axios.delete(`${appConstants.COUNTY_PROFILES_URL}/${this.state.id}.json`)
      .then(res => {
        return window.location.href = appConstants.COUNTY_PROFILES_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('county_profile', error.response.data)});
      })
    }
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          <span dangerouslySetInnerHTML={{__html: this.state.alertMessage}}></span>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  }

  handleFileSelect = (e) => {
    const document = e.target.id
    this.setState({[`${document}BlobIdOrFile`]: "" })
    this.setState({[`${document}Url`]: "" })
    this.setState({[`${document}File`]: e.target.files[0]})
    this.setState({[`${document}Filename`]: e.target.files[0].name})
  }

  handleFileClear = (document) => {
    this.setState({[`${document}BlobIdOrFile`]: "" })
    this.setState({[`${document}File`]: null})
    this.setState({[`${document}Filename`]: ""})
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleURLBlur = (e) => {
    const document = e.target.name.slice(0, -3)
    if (!validURL(e.target.value) && this.state[`${document}File`] == null) {
      this.setState({
        alertMessage: `Please enter a valid file URL or upload a file for the ${document} field`,
      });
    } else if (!validURL(e.target.value) && this.state[`${document}File`]) {
      this.setState({ [[`${document}Url`]]: "" })
    }
  }

  // County Contact functions
  setModalContact = (c) => {
    this.setState({
      modalContact: c
    }, () => this.showContactModal())
  }

  refreshContacts = async() => {
    const res = await axios.get(`${appConstants.COUNTY_PROFILES_URL}/${this.state.id}/contacts.json`)
    this.setState({contacts: res.data})
  }

  deleteContact = (c) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.delete(`${appConstants.COUNTY_CONTACTS_URL}/${c.id}.json`)
    .then(() => this.refreshContacts())
    .catch(error => {
      console.log(error);
      this.setState({ alertMessage: formatValidationErrors('county_contact', error.response.data)})
    })
  }

  showContactModal = () => {
    this.setState({showContactModal: true})
  }

  closeContactModal = () => {
    this.setState({
      showContactModal: false
    }, () => this.refreshContacts())
  }

  // County Activity functions
  setModalActivity = (a) => {
    this.setState({
      modalActivity: a
    }, () => this.showActivityModal())
  }

  refreshActivities = async() => {
    const res = await axios.get(`${appConstants.COUNTY_PROFILES_URL}/${this.state.id}/activities.json`)
    this.setState({activities: res.data})
  }

  deleteActivity = (a) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.delete(`${appConstants.COUNTY_ACTIVITIES_URL}/${a.id}.json`)
    .then(() => this.refreshActivities())
    .catch(error => {
      console.log(error);
      this.setState({ alertMessage: formatValidationErrors('county_activity', error.response.data)})
    })
  }

  showActivityModal = () => {
    this.setState({showActivityModal: true})
  }

  closeActivityModal = () => {
    this.setState({
      showActivityModal: false
    }, () => this.refreshActivities())
  }

  // render functions
  renderContacts() {
    return (
      <Table size="sm">
        <tbody>
          {this.state.contacts.map((c,i) => <tr key={i}><td>{c.name},&nbsp;{c.title},&nbsp;{c.organization}</td><td className="county-profile-list-button"><Button onClick={()=>this.setModalContact(c)} variant="link"><BiPencil /></Button></td><td className="county-profile-list-button"><Button onClick={()=>this.deleteContact(c)} variant="link"><BiTrash /></Button></td></tr>)}
        </tbody>
      </Table>
    )
  }

  renderActivities() {
    return (
      <Table size="sm">
        <tbody>
          {this.state.activities.map((a,i) => <tr key={i}><td>{a.name}</td><td className="county-profile-list-button"><Button onClick={()=>this.setModalActivity(a)} variant="link"><BiPencil /></Button></td><td className="county-profile-list-button"><Button onClick={()=>this.deleteActivity(a)} variant="link"><BiTrash /></Button></td></tr>)}
        </tbody>
      </Table>
    )
  }

  renderAssociations() {
    return this.state.id ? (
      <Form.Row>
        <Form.Group as={Col} md={6} controlId="profileForm.contacts">
          <Form.Label>Contacts &nbsp; <Button variant="outline-primary" size={"sm"} onClick={() => this.setModalContact({countyProfileId: this.props.profile.id})} className="add-collection-btn"><BiPlus /></Button></Form.Label>
          {this.renderContacts()}
        </Form.Group>
        <Form.Group as={Col} md={6} controlId="profileForm.activities">
          <Form.Label>Activities &nbsp; <Button variant="outline-primary" size={"sm"} onClick={() => this.setModalActivity({countyProfileId: this.props.profile.id})} className="add-collection-btn"><BiPlus /></Button></Form.Label>
          {this.renderActivities()}
        </Form.Group>
      </Form.Row>
    ) : (
      <Form.Row>
        <Form.Group as={Col} md={12} controlId="profileForm.associations">
          <div><i>{I18n.t('activerecord.attributes.county_profile.save_before_adding_associations')}</i></div>
        </Form.Group>
      </Form.Row>
    )
  }

  render() {
    return (
      <div>
      <h2>{this.props.profile.id ? I18n.t('navigation.edit') : I18n.t('navigation.add')} County</h2>
      <Form>
        <Modal
          show={this.state.showContactModal}
          onHide={this.closeContactModal}
          keyboard={true}
          size={'lg'}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalContact.name ? I18n.t('headers.county_contact.edit') : I18n.t('headers.county_contact.new')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CountyContactForm
              contact={this.state.modalContact}
              countyProfileId={this.state.id}
              isModal={true}
              closeModal={this.closeContactModal}
              authenticityToken={this.props.authenticityToken} />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showActivityModal}
          onHide={this.closeActivityModal}
          keyboard={true}
          size={'lg'}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalActivity.name ? I18n.t('headers.county_activity.edit') : I18n.t('headers.county_activity.new')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CountyActivityForm
              activity={this.state.modalActivity}
              countyProfileId={this.state.id}
              isModal={true}
              closeModal={this.closeActivityModal}
              authenticityToken={this.props.authenticityToken} />
          </Modal.Body>
        </Modal>

        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Form.Row>
          <Form.Group as={Col} md={4} controlId="profileForm.name">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.county_name')}</Form.Label>
            <Form.Control 
              name="countyName"
              type="text"
              value={this.state.countyName}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={2} controlId="profileForm.population">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.population')}</Form.Label>
            <Form.Control 
              name="population"
              type="number"
              value={this.state.population}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={2} controlId="profileForm.displaced">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.displaced')}</Form.Label>
            <Form.Control 
              name="displaced"
              type="number"
              value={this.state.displaced}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={2} controlId="profileForm.people_in_need">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.people_in_need')}</Form.Label>
            <Form.Control 
              name="peopleInNeed"
              type="number"
              value={this.state.peopleInNeed}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={2} controlId="profileForm.returnees">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.returnees')}</Form.Label>
            <Form.Control 
              name="returnees"
              type="number"
              value={this.state.returnees}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={4} controlId="profileForm.ethnicities">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.ethnicity')}</Form.Label>
            <Form.Control 
              name="ethnicities"
              type="text"
              value={this.state.ethnicities}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={2} controlId="profileForm.food_security_percentage">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.food_security_percentage')}</Form.Label>
            <Form.Control 
              name="foodSecurityPercentage"
              type="number"
              value={this.state.foodSecurityPercentage}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="profileForm.foodSecurityUrl">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.food_security_report_url')}</Form.Label>
            <Form.Control 
              name="foodSecurityReportUrl"
              type="text"
              value={this.state.foodSecurityReportUrl}
              onChange={this.handleInputChange}
              onBlur={this.handleURLBlur} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={4} controlId="profileForm.livelihoods">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.livelihoods')}</Form.Label>
            <Form.Control 
              name="livelihoods"
              as="textarea"
              rows={2}
              value={this.state.livelihoods}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="profileForm.governance">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.governance')}</Form.Label>
            <Form.Control 
              name="governance"
              as="textarea"
              rows={2}
              value={this.state.governance}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="profileForm.roads">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.roads')}</Form.Label>
            <Form.Control 
              name="roads"
              as="textarea"
              rows={2}
              value={this.state.roads}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={4} controlId="profileForm.network">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.network')}</Form.Label>
            <Form.Control 
              name="network"
              as="textarea"
              rows={2}
              value={this.state.network}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="profileForm.airport">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.airport')}</Form.Label>
            <Form.Control 
              name="airport"
              as="textarea"
              rows={2}
              value={this.state.airport}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="profileForm.lastFlyAway">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.last_fly_away_date')}</Form.Label><br/>
            <SingleDatePicker
              date={this.state.lastFlyAway}// momentPropTypes.momentObj or null
              onDateChange={lastFlyAway => this.setState({ lastFlyAway })} // PropTypes.func.isRequired
              focused={this.state.lastFlyAwayFocused} // PropTypes.bool
              onFocusChange={({ focused: lastFlyAwayFocused }) => this.setState({ lastFlyAwayFocused })} // PropTypes.func.isRequired
              id="lastFlyAway" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
            />
          </Form.Group>        
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="profileForm.county_map_file">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.upload_county_map_file')}</Form.Label>
            <InputGroup>
              <Form.File id="formcheck-api-custom" 
                disabled={this.state.countyMapUrl}
                custom>
                <Form.File.Input 
                  id="countyMap" 
                  onChange={this.handleFileSelect}
                  disabled={this.state.countyMapUrl} />
                <Form.File.Label>
                  {this.state.countyMapFilename || I18n.t('resource.upload_file')} 
                </Form.File.Label>
              </Form.File>
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => this.handleFileClear('countyMap')}><BiReset /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="profileForm.county_map_url">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.provide_county_map_url')}</Form.Label>
            <Form.Control 
              name="countyMapUrl"
              type="text"
              value={this.state.countyMapUrl}
              onChange={this.handleInputChange}
              onBlur={this.handleURLBlur}
              disabled={this.state.countyMapFile} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="profileForm.location_map_file">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.upload_location_map_file')}</Form.Label>
            <InputGroup >
              <Form.File id="formcheck-api-custom" 
                disabled={this.state.locationMapUrl}
                custom>
                <Form.File.Input 
                  id="locationMap" 
                  onChange={this.handleFileSelect}
                  disabled={this.state.locationMapUrl} />
                <Form.File.Label>
                  {this.state.locationMapFilename || I18n.t('resource.upload_file')} 
                </Form.File.Label>
              </Form.File>
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => this.handleFileClear('locationMap')}><BiReset /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="profileForm.location_map_url">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.provide_location_map_url')}</Form.Label>
            <Form.Control 
              name="locationMapUrl"
              type="text"
              value={this.state.locationMapUrl}
              onChange={this.handleInputChange}
              onBlur={this.handleURLBlur}
              disabled={this.state.locationMapFile} />
          </Form.Group>            
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="profileForm.plso_report_file">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.upload_plso_report_file')}</Form.Label>
            <InputGroup >
              <Form.File id="formcheck-api-custom" 
                disabled={this.state.plsoReportUrl}
                custom>
                <Form.File.Input 
                  id="plsoReport" 
                  onChange={this.handleFileSelect}
                  disabled={this.state.plsoReportUrl} />
                <Form.File.Label>
                  {this.state.plsoReportFilename || I18n.t('resource.upload_file')} 
                </Form.File.Label>
              </Form.File>
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => this.handleFileClear('plsoReport')}><BiReset /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="profileForm.plso_report_url">
            <Form.Label>{I18n.t('activerecord.attributes.county_profile.provide_plso_report_url')}</Form.Label>
            <Form.Control 
              name="plsoReportUrl"
              type="text"
              value={this.state.plsoReportUrl}
              onChange={this.handleInputChange}
              onBlur={this.handleURLBlur}
              disabled={this.state.plsoReportFile} />
          </Form.Group>            
        </Form.Row>
        {this.renderAssociations()}
        <Form.Group controlId="profileForm.buttons">
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          <Button variant="outline-secondary" onClick={()=>this.props.setPage('show')}>{I18n.t("navigation.cancel")}</Button> &nbsp;
          {this.state.id ? <Button variant="outline-danger" onClick={this.handleDelete}>{I18n.t("navigation.delete")}</Button> : ""}
        </Form.Group>
      </Form>
      </div>
    );
  };
}

export default CountyProfileForm
