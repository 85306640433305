import React from 'react'
import I18n from 'i18n-js/locales.js'
import Table from 'react-bootstrap/Table';

export default function CountyProfileKeyInfo(props) {
  const renderContactRow = (c, index) => {
    return (
      <tr key={index}>
        <td>{c["contact_type_name"]}</td>
        <td>{c["name"]}</td>
        <td>{c["title"]}</td>
        <td>{c["organization"]}</td>
        <td>{c["info"]}</td>
      </tr>
    )
  }
  
  return (
    <><br/>
      <h2 className="county-profile-header-text">{I18n.t('activerecord.attributes.county_profile.key_info')}</h2>
      <p>{I18n.t('activerecord.attributes.county_profile.last_fly_away_date')}: {props.lastFlyAway}</p>
      <p>{I18n.t('activerecord.attributes.county_profile.plso_security_risk_assessment')}: {props.plsoReportUrl ? <a className="county-profile-red-text" href={props.plsoReportUrl} target="_blank">{`${props.countyName} ${I18n.t('activerecord.attributes.county_profile.plso_risk_assessment')}`}</a> : ""}</p>
      <p>{I18n.t('activerecord.attributes.county_profile.key_contacts')}:</p>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{I18n.t('activerecord.attributes.county_contact.type')}</th>
            <th>{I18n.t('activerecord.attributes.county_contact.name')}</th>
            <th>{I18n.t('activerecord.attributes.county_contact.title')}</th>
            <th>{I18n.t('activerecord.attributes.county_contact.institution')}</th>
            <th>{I18n.t('activerecord.attributes.county_contact.info')}</th>
          </tr>
        </thead>
        <tbody>
          {props.contacts.map((c,i) => renderContactRow(c, i))}
        </tbody>
      </Table>
    </>
  )
}