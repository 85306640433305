import React from 'react'
import I18n from 'i18n-js/locales.js'
import Image from 'react-bootstrap/Image'
import CountyProfilePopulationInfo from './CountyProfilePopulationInfo'
import CountyProfileGeneralInfo from './CountyProfileGeneralInfo'
import CountyProfileKeyInfo from './CountyProfileKeyInfo'
import CountyProfileActivities from './CountyProfileActivities'


const CountyProfile = React.forwardRef((props, ref) => {
  const renderMap = () => {
    return (
      <div className=""><br/>
        <h2 className="county-profile-header-text">{I18n.t('activerecord.attributes.county_profile.map_of')}&nbsp;{props.profile["county_name"]}</h2>
        <p>{I18n.t('activerecord.attributes.county_profile.click_map_for_gis')}</p>
        <a href={props.profile["gis_map_url"]} className="d-flex justify-content-center" target="_blank"><Image fluid src={props.profile["county_map_url"] || props.profile["county_map_uploaded_url"]} /></a>
      </div>
    )
  }

  const renderUpdatedDate = () => {
    return (
      <div>
        <p><i>{`${I18n.t('activerecord.attributes.county_profile.updated_at')}: ${props.profile["updated_at"]}`}</i></p>
      </div>
    )
  }

  return (
    <div className="container-fluid county-profile-medium-text" ref={ref}>
      <div>
        <Image src={require("../../assets/images/county_profiles_header.png")} fluid />
      </div><br/>

      <CountyProfilePopulationInfo
        countyName={props.profile["county_name"]}
        population={props.profile["population"]}
        displaced={props.profile["displaced"]}
        peopleInNeed={props.profile["people_in_need"]}
        returnees={props.profile["returnees"]}
        foodSecurityPercentage={props.profile["food_security_percentage"]}
        ethnicities={props.profile["ethnicities"]}
        locationMap={props.profile["location_map_url"] || props.profile["location_map_uploaded_url"]} />

      <CountyProfileGeneralInfo
        foodSecurityReportUrl={props.profile["food_security_report_url"]}
        livelihoods={props.profile["livelihoods"]}
        governance={props.profile["governance"]}
        roads={props.profile["roads"]}
        network={props.profile["network"]}
        airport={props.profile["airport"]} />

      <CountyProfileKeyInfo
        lastFlyAway={props.profile["last_fly_away"]}
        plsoReportUrl={props.profile["plso_report_url"] || props.profile["plso_report_uploaded_url"] || ""}
        countyName={props.profile["county_name"]}
        contacts={props.contacts} />

      <CountyProfileActivities
        activities={props.activities} />

      {renderMap()}
      {renderUpdatedDate()}
    </div>
  )
})

export default CountyProfile;
