import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js/locales.js'
import ImportForm from '../forms/ImportForm'
import { appConstants } from 'utils/constants.js'
import { Container, Row, Col, Button, Tab, Tabs, Spinner, Pagination, Form } from 'react-bootstrap'
import axios from 'axios'
import ReactJson from 'react-json-view'
import { CSVLink } from 'react-csv'
import SortableTable from '../tables/SortableTable.js'
import { BiSync, BiCheckDouble } from 'react-icons/bi'

export default function FulcrumFormDetails(props) {
  const [fulcrumForm, setFulcrumForm] = useState({})
  const [webhook, setWebhook] = useState({})
  const [questions, setQuestions] = useState([])
  const [questionColumns, setQuestionColumns] = useState([])
  const [records, setRecords] = useState([])
  const [recordColumns, setRecordColumns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [syncRecordID, setSyncRecordID] = useState("")
  const [recordsPage, setRecordsPage] = useState(1)
  const [recordsTotalPages, setRecordsTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [recordsPerPage, setRecordsPerPage] = useState(100)


  const loadForm = async() => {
    setIsLoading(true)
    const res = await axios.get(`${appConstants.FULCRUM_FORMS_URL}/${props.fulcrumFormId}?page=${recordsPage}&per_page=${recordsPerPage}`)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    setFulcrumForm(res.data.form)
    setWebhook(res.data.webhook)
    setQuestions(res.data.questions)
    setQuestionColumns(res.data.question_columns)
    setRecords(res.data.records)
    setRecordColumns(res.data.record_columns)
    setRecordsPage(res.data.current_page)
    setRecordsTotalPages(res.data.page_count)
    setTotalRecords(res.data.record_count)
    setIsLoading(false)
  }

  const syncRecord = async(recordID) => {
    setSyncRecordID(recordID)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.put(`${appConstants.FULCRUM_FORMS_URL}/${props.fulcrumFormId}/sync_record/${recordID}?page=${recordsPage}&per_page=${recordsPerPage}`)
    setRecords(res.data.records)
    setRecordColumns(res.data.record_columns)
    setSyncRecordID("")
  }

  const renderDownloadLink = () => {
    return questions.length > 0 ? (
      <CSVLink data={questions}>{I18n.t("headers.fulcrum_form.download_questions")}</CSVLink>
    ) : ""
  }

  const externalRecordLink = (cell, row) => {
    let cellContent = cell.render('Cell') 
    if (cell["column"]["id"] === "record_id") {
      cellContent = <a href={appConstants.FULCRUM_URL + "records/" + row["original"]["record_id"]} target="_blank" rel="noopener noreferrer" key={row["original"]["record_id"]}>{row["original"]["record_id"]}</a>
    } else if (cell["column"]["id"] === "is_synchronized" && row["original"]["is_synchronized"] == "False" && syncRecordID != row["original"]["record_id"]) {
      cellContent = <Button  variant="outline-secondary" size="sm" onClick={() => syncRecord(row["original"]["record_id"])}><BiSync /></Button>
    } else if (cell["column"]["id"] === "is_synchronized" && row["original"]["is_synchronized"] == "True" && syncRecordID != row["original"]["record_id"]) {
      cellContent = <Button  variant="outline-success" size="sm" onClick={() => syncRecord(row["original"]["record_id"])}><BiCheckDouble /></Button>
    } else if (cell["column"]["id"] === "is_synchronized" && syncRecordID == row["original"]["record_id"]) {
      cellContent = <Button  variant="outline-secondary" size="sm" ><Spinner animation="grow" size="sm" /></Button>
    }

    return (
      cellContent 
    )
  }

  useEffect(() => {
    loadForm()
  }, [recordsPage, recordsPerPage])

  const renderPagination = () => {
    let pages = []
    for (let page = 1; page <= recordsTotalPages; page++) {
      pages.push(
        <Pagination.Item key={page} active={page === recordsPage} onClick={() => setRecordsPage(page)} >
          {page}
        </Pagination.Item>,
      )
    }

    return (
      <Row>
        <Col md={10}>
          <Pagination size='sm'>
            <Pagination.First onClick={() => setRecordsPage(1)} />
            <Pagination.Prev onClick={() => setRecordsPage(recordsPage == 1 ? 1 : recordsPage-1)} />
            {pages}
            <Pagination.Next onClick={() => setRecordsPage(recordsPage == recordsTotalPages ? recordsTotalPages : recordsPage+1)} />
            <Pagination.Last onClick={() => setRecordsPage(recordsTotalPages)} />
          </Pagination>
        </Col>
        <Col md={2}>
          <Form.Control as="select" size='sm' style={{float: 'right'}} defaultValue={recordsPerPage} onChange={(e) => setRecordsPerPage(e.target.value)}>
            <option value="10">10 {I18n.t("headers.fulcrum_form.per_page")}</option>
            <option value="25">25 {I18n.t("headers.fulcrum_form.per_page")}</option>
            <option value="50">50 {I18n.t("headers.fulcrum_form.per_page")}</option>
            <option value="100">100 {I18n.t("headers.fulcrum_form.per_page")}</option>
            <option value="250">250 {I18n.t("headers.fulcrum_form.per_page")}</option>
            <option value="500">500 {I18n.t("headers.fulcrum_form.per_page")}</option>
            <option value="1000">1000 {I18n.t("headers.fulcrum_form.per_page")}</option>
          </Form.Control>
        </Col>
      </Row>
    )
  }

  const renderAll = () => {
    return isLoading  ? (
      <Spinner animation="grow" />
    ) : <Container>
          <Row>
            <Col md={3}>
              <dt>{I18n.t('activerecord.attributes.fulcrum_form.name')}</dt>
            </Col>
            <Col md={9}>
              <dd>{fulcrumForm.name}</dd>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <dt>{I18n.t('activerecord.attributes.fulcrum_form.external_id')}</dt>
            </Col>
            <Col md={9}>
              <dd><a href={appConstants.FULCRUM_URL + "apps/" + fulcrumForm.external_id + "/edit"} target="_blank" rel="noopener noreferrer">{fulcrumForm.external_id}</a></dd>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <dt>{I18n.t("headers.fulcrum_form.total_records")}</dt>
            </Col>
            <Col md={9}>
              <dd>{totalRecords}</dd>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button variant="outline-primary" style={{float: 'right'}} onClick={() => {loadForm()}}>{I18n.t("headers.fulcrum_form.update_remote")}</Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <br />
              <Tabs defaultActiveKey="records" id="listTabs">
                <Tab eventKey="questions" title={I18n.t("headers.fulcrum_form.questions")}>
                  <SortableTable
                    data={questions}
                    columns={questionColumns}
                    useRenderFunction={false}
                  />  
                </Tab>
                <Tab eventKey="records" title={I18n.t("headers.fulcrum_form.records")} className="mt-3">
                  {renderPagination()}
                  <SortableTable
                    data={records}
                    columns={recordColumns}
                    useRenderFunction={true}
                    cellContent={externalRecordLink}
                  />
                  {renderPagination()}
                </Tab>
                <Tab eventKey="uploaded_questions" title={I18n.t("headers.fulcrum_form.upload_questions")}>
                  <br />
                  {renderDownloadLink()} 
                  <br /><br />
                  <ImportForm 
                    userID = {props.userID}
                    schema = {props.schema}
                    guidance = {"import.question_guidance"}
                    importUrl = {`${appConstants.QUESTION_IMPORT_URL}/${props.fulcrumFormId}`}
                    authenticityToken = {props.authenticityToken}
                    licenseKey = {props.licenseKey}
                  />
                </Tab>
                <Tab eventKey="webhook" title={I18n.t("headers.fulcrum_form.webhook")}>
                  <ReactJson src={webhook} collapsed={false} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
  }

  return (
    <>
      {renderAll()} 
    </>
  )
}
