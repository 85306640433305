import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.1s ease;
  background-color: ${props => 
    props.hold
      ?'mistyrose'
      : props.isDragging 
      ? 'aliceblue' 
      : 'white'}
`;

export default function Task(props){

    const isDragDisabled = false
    const taskContent = props.taskContent
    return (
      <Draggable 
        draggableId={props.task.id} 
        index={props.index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
            hold={props.task.hold}
          >
            {taskContent(props.task)}

          </Container>
        )}
      </Draggable>
    )
}