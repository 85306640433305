import React from 'react'

export default function Iframe(props) {
  
  return (
    <>          
      <iframe src={props.source} height={props.height} width={props.width}/>         
    </>
  )
}
