import React, { Component }  from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'

export default class CPForm extends Component {

  state = {
    id: this.props.implementing_partner.id || "",
    name: this.props.implementing_partner.name || "",
    activityIDs: this.props.implementing_partner.activities.map(i => (i.id)) || [],
    alertMessage: "",
  }


  createFormData = () => {
    let formData = new FormData()
    formData.append('implementing_partner[name]', this.state.name)
    this.state.activityIDs.length > 0
      ? this.state.activityIDs.forEach(activitiyId => {
        formData.append('implementing_partner[activity_ids][]', activitiyId)
      })
      : formData.set('implementing_partner[activity_ids][]', "")

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const cp = this.createFormData()
    if (this.state.id) {
      axios.put(`${appConstants.IMPLEMENTING_PARTNERS_URL}/${this.state.id}`, cp )
      .then(res => {
        return window.location.href = appConstants.IMPLEMENTING_PARTNERS_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('cooperating_partner', error.response.data) });
      })
    } else {
      axios.post(appConstants.IMPLEMENTING_PARTNERS_URL, cp )
      .then(res => {
        return window.location.href = appConstants.IMPLEMENTING_PARTNERS_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('cooperating_partner', error.response.data) });
      })
    }
  }


  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleActivitiesChange = (e) => {
    const activityIDs = e != null ? e.map(t=> t.value) : []
    this.setState({
      activityIDs: activityIDs
    })
  }

  
  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <Alert variant={'info'} onClose={() => this.setState({ alertMessage: null })} dismissible>
          {this.state.alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };


  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={6} className="mb-3" controlId="questionForm.data_name">
          <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.implementing_partner')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={this.state.name}
            onChange={this.handleInputChange} />
          </Form.Group>

            <Form.Group as={Col} md={6} controlId="resourceForm.activity">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
              <SelectMultiple
                selected={this.state.activityIDs}
                onChange={this.handleActivitiesChange}
                url={appConstants.ACTIVITIES_URL + ".json"}
                placeholder={I18n.t('selects.multiple_optional')} />
            </Form.Group>
        </Row>


        <Form.Group className="mb-3">
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={appConstants.IMPLEMENTING_PARTNERS_URL}>{I18n.t("navigation.cancel")}</Button>
        </Form.Group>
      </Form>
    );
  }
}
