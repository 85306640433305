import React from 'react'
import ThematicChart from '../charts/ThematicChart'
import { Row } from 'react-bootstrap'


export default function ThematicQuestionGroup(props) {

  const questions = props.questions

  return (
    <Row>
      {questions.map((question, index) => question.counts ? <ThematicChart key={index} columns={question.columns || 4}label={question.name} data={question.counts} chartType={question.chart_type || "pie"} /> : "")}   
    </Row>
  )
}
