import React, { useEffect, useState }  from "react";
import axios from 'axios'
import styled from 'styled-components'

const SimpleListBox = styled.div`
  padding-bottom: 15px;
  `;

const SimpleListRow = styled.div`
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  `;

const SimpleListText = styled.div`
  color: #333;
  `;


export default function SimpleList(props) {
  const [values, setValues] = useState([])

  const getValues = async () => {
    let queryString = ""
    if (props.filters) {
      queryString = "?"
      Object.keys(props.filters).forEach(key => queryString = queryString + key + "=" + (props.filters[key] ? String(props.filters[key]) : "") + "&")
    }

    const res = await axios.get(props.url + queryString)
    setValues(res.data)
  }

  useEffect(() => {
    getValues()
  }, [props.filters, props.url])

  const renderRow = (o) => {
    return (
      <SimpleListRow key={o.id}>
        <SimpleListText>
          {o.url ? <a href={o.url} target="_blank" rel="noopener noreferrer">{o.name}</a> : o.name} {o.upload_date ? <span> - {new Date(o.upload_date).toDateString()}</span> : ""}
        </SimpleListText>
      </SimpleListRow>
    )
  }

  return (
    <SimpleListBox>
      <h5>{props.title}</h5>
      { values.map(o =>
          renderRow(o)
        )
      }
    </SimpleListBox>
  );
}
