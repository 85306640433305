import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import I18n from 'i18n-js/locales.js'

export default function AdminFilters(props) {


  const renderSearch = () => {
    return (
      !props.excludeSearch
      ?   <Row  className="p-2">
            <Col>
              <Form.Control type="text" placeholder={I18n.t("headers.monitoring_request.search")} onChange={(e) => props.handleSearch(e)} />
            </Col>
          </Row>
      : ""
    )
  }


  return (
      <Form  className="border rounded bg-light mb-2">
        {renderSearch()}
      </Form>
  )
}
