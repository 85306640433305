import React, { useEffect, useState } from 'react'
import { appConstants } from 'utils/constants.js'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { BiChevronRight, BiChevronLeft } from "react-icons/bi"
import styled from 'styled-components'
import MonitoringRequestInfo from '../details/MonitoringRequestInfo'
import Iframe from '../details/Iframe'

const PDFCol = styled(Col)`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export default function ResourceDetails(props) {
  const [resource, setResource] = useState({})
  const [resourceTags, setResourceTags] = useState([])
  const [resourceCollections, setResourceCollections] = useState([])
  const [resourceActivities, setResourceActivities] = useState([])

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const loadResource = async() => {
    const res = await axios.get(`${appConstants.RESOURCES_URL}${props.id}.json`)
    setResource(res.data)
    setResourceTags(res.data["tags"])
    setResourceCollections(res.data["collections"])
    setResourceActivities(res.data["activities"])
  }

  useEffect(() => {
    loadResource()
  }, [props.id])

  const renderURL = () => {
    return resource.url && !resourceTags.includes("360 Panorama") ? (
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.url')}</dt>
        </Col>
        <Col md={9}>
          <dd><a href={resource.url} target="_blank" rel="noopener noreferrer">{resource.show_url || resource.url}</a></dd>
        </Col>
      </Row>
    ) : resource.url && resourceTags.includes("360 Panorama") ? (
        <Iframe source={resource.url} height={'600px'} width={'100%'} />
    ) : ""
  }

  const renderFile = () => {
    return resource.file_url && !resourceTags.includes("Photograph") && !resourceTags.includes("Map") ? (
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.file')}</dt>
        </Col>
        <Col md={9}>
          <dd><a href={resource.file_url} target="_blank" rel="noopener noreferrer">{resource.filename}</a></dd>
        </Col>
      </Row>
    ) : resource.file_url && (resourceTags.includes("Photograph") || resourceTags.includes("Map")) ? (
      <Card style={{ width: '100%', marginBottom: '20px' }}>
        <Card.Img variant="top" src={resource.file_url} />
      </Card>
    ) : ""
  }

  const renderPDFViewer = () => {
    const url = resource.file_url ? resource.file_url : resource.url
    const extension = url ? url.substring(url.lastIndexOf('.') + 1) : ""
    
    return extension == 'pdf' ? (
      <Row>
        <PDFCol md={12}>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} height={1100} scale={2} renderTextLayer={false} />
          </Document>
          <div>
            <Button
              variant="outline-secondary"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <BiChevronLeft />
            </Button>
              <span className="text-muted">&nbsp;Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}&nbsp;</span>
            <Button
              variant="outline-secondary"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <BiChevronRight />
            </Button>
          </div>
        </PDFCol>
      </Row>
    ) : ""
  }

const renderMonitoringRequestInfo = () => {
  return resource.monitoring_request_id ? (
    <div>
      <h5>{I18n.t('activerecord.attributes.resource.linked_monitoring_request')}</h5>
      <MonitoringRequestInfo monitoringRequestId={resource.monitoring_request_id} />
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </div>
  ) : ""
}

const renderDescription = () => {
  return resource.description ? (
    <Row>
      <Col md={3}>
        <dt>{I18n.t('activerecord.attributes.resource.description')}</dt>
      </Col>
      <Col md={9}>
        <dd>{resource.description}</dd>
      </Col>
    </Row>
  ) : ""
}

  const renderEditButton = () => {
    return resource.can_edit ? (
      <Button variant="outline-primary" href={`${appConstants.RESOURCES_URL}${props.id}/edit`}>{I18n.t('navigation.edit')}</Button>
    ) : ""
  }

  const renderCollectionButton = () => {
    return Object.keys(props.collection).length > 0 ? (
      <Button variant="outline-secondary" onClick={()=>props.openCollection(props.collection)}>{I18n.t('navigation.back')}</Button>
    ) : ""
  }

  const renderDeleteButton = () => {
    return resource.can_delete ? (
      <Button variant="outline-danger" href={`${appConstants.RESOURCES_URL}${props.id}/delete`}>{I18n.t('navigation.delete')}</Button>
    ) : ""
  }

  return (
    <Container>
      {renderURL()}
      {renderFile()}
      {renderDescription()}
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.fiscal_year')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.fiscal_year}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.user')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.user_name}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.updated_at')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.updated_at}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.tags')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resourceTags.map((t, i) => <span className="badge badge-secondary" key={i}>{t}</span>)}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.activities')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resourceActivities.map((a, i) => <span className="badge badge-info" key={i}>{a.name}</span>)}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.public')}</dt>
        </Col>
        <Col>
          <dd>{resource.is_public ? 'Yes' : 'No' }</dd>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <dt>{I18n.t('activerecord.attributes.resource.direct_link')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.direct_link}</dd>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {renderMonitoringRequestInfo()}
        </Col>
      </Row>
      <Row>
        <Col md={1}>
          {renderEditButton()}
        </Col>
        <Col md={9}>
          {renderCollectionButton()}
        </Col>
        <Col md={2}>
          {renderDeleteButton()}
        </Col>
      </Row>
      {renderPDFViewer()}
    </Container>
  )
}
