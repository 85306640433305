import React from 'react'
import ThematicAccordion from '../accordions/ThematicAccordion'
import { Row, Col } from 'react-bootstrap'

export default function ThematicDetails(props) {

  return (
    <><br/>
      <Row>
        <Col md={12}>
          {props.sections  ? (<ThematicAccordion sections={props.sections} />) : ""}
        </Col>
      </Row>
    </>
  )
}
