import React from 'react'
import I18n from 'i18n-js/locales.js'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

export default function CountyProfilePopulationInfo(props) {
  return (
    <div>
    <h2 className="county-profile-red-text">{props.countyName}</h2>
      <Row>
        <Col md={9} sm={9} xs={8}>
          <Row>
            <Col>
              <Card className="county-profile-card">
                <Card.Body className="p-2">
                  <Image className="county-profile-icon" src={require("../../assets/images/Group.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.population')}: <span className="county-profile-red-text">{props.population}</span></span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="county-profile-card">
                <Card.Body className="p-2">
                  <Image className="county-profile-icon" src={require("../../assets/images/Internally-displaced.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.displaced')}: <span className="county-profile-red-text">{props.displaced}</span></span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="county-profile-card">
                <Card.Body className="p-2">
                  <Image className="county-profile-icon" src={require("../../assets/images/Protection.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.people_in_need_full')}*: <span className="county-profile-red-text">{props.peopleInNeed}</span></span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="county-profile-card">
                <Card.Body className="p-2">
                  <Image className="county-profile-icon" src={require("../../assets/images/Population-return.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.returnees')}: <span className="county-profile-red-text">{props.returnees}</span></span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="county-profile-card">
                <Card.Body className="p-2">
                  <Image className="county-profile-icon" src={require("../../assets/images/Food-Security.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.food_security_and_nutrition')}: <span className="county-profile-red-text">{props.foodSecurityPercentage}% of Population</span></span>
                </Card.Body>
              </Card>
              <Button variant="link" className="county-profile-small-link" href="https://data.humdata.org/dataset/south-sudan-humanitarian-needs-overview">*https://data.humdata.org/dataset/south-sudan-humanitarian-needs-overview</Button>
            </Col>
            <Col>
              <Card className="county-profile-card">
                <Card.Body className="p-2">
                  <Image className="county-profile-icon" src={require("../../assets/images/Conflict.png")} /><span className="county-profile-info-text">{I18n.t('activerecord.attributes.county_profile.ethnicity')}: <span className="county-profile-red-text">{props.ethnicities}</span></span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={3} sm={3} xs={2} className="pb-3">
          <Image className="county-profile-location-map" src={props.locationMap} />
        </Col>
      </Row>
    </div>
  )
}