import React from 'react'
import ThematicAccordionContent from './ThematicAccordionContent'
import { Accordion } from 'react-bootstrap'


export default function ThematicAccordion(props) {

  const sections = props.sections

  return (
    <Accordion defaultActiveKey={1}>
      {sections.map((section, index) => <ThematicAccordionContent key={index} section={section} index={index+1} />)}            
    </Accordion>
  )
}
