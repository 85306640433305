import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiTrash } from 'react-icons/bi';
import { FaStar } from "react-icons/fa";

export default function SimpleListForm(props) {

  const selectItem = (e) => {
    const item = props.listItems.find(i => i.uuid === e);
    props.selectItem(item)
  }

  const deleteItem = (e) => {
    props.deleteItem(e.currentTarget.value);
  }

  const renderSaveForm = () => {
    return (props.showSaveForm ?
      <InputGroup className="px-3 pb-3">
        <FormControl
          id="new-list-item-name-input"
          placeholder={props.formPlaceholder}
          aria-label="Name of saved list item" />
        <InputGroup.Append>
          <Button onClick={props.saveItem}><FaStar /></Button>
        </InputGroup.Append>
      </InputGroup>
    : "")
  }

  const renderSavedItems = () => {
    return props.listItems.length > 0 ? (
      props.listItems.map(s =>
        <Dropdown.Item key={s.uuid} eventKey={s.uuid} className="pl-1 saved-list-item-option" onSelect={selectItem}>
          <Button
            value={s.uuid}
            onClick={deleteItem}
            variant="link"
            className="btn-delete-saved-list-item">
            <BiTrash />
          </Button>
          {s.name}
        </Dropdown.Item>
      )
    ) : (<div className="p-3">{props.itemPlaceholder}</div>)
  }
        

  return (
    <div>
      {renderSaveForm()}
      {renderSavedItems()}
    </div>
  )

}
