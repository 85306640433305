import React, { Component }  from 'react'
import styled from 'styled-components'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import ThematicDetails from './details/ThematicDetails'
import { Collapse, Tabs, Tab } from 'react-bootstrap'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import { appConstants } from 'utils/constants.js'
import axios from 'axios'


const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;
  
export default class ThematicDashboard extends Component {

  state = {
    initialData: this.props.initialData || [],
    filters: this.props.filters || {},
    showFilters: true,
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }


  filterData = (filters) => {
    let queryString = "?"

    Object.keys(filters).forEach(key => queryString = queryString + key + "=" + (filters[key] ? JSON.stringify(filters[key]) : "") + "&")
    axios.get(`${appConstants.THEMATIC_SURVEYS_URL}${queryString}`)
    .then(res => {
      const data = res.data
      this.setState({
        initialData: data,
        filters: filters,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }


  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  
  renderDashboard() {
    return (
      <Tabs defaultActiveKey="0" id="listTabs">
        {this.state.initialData.map((survey, index) => {
          const id = survey.id
          const name = survey.name
          const sections = survey.data.sections

          return <Tab key={id} eventKey={index} title={name}><ThematicDetails key={id} name={name} sections={sections} /></Tab>
        })}
      </Tabs>
    )
  }


  render() {
    return (
      <>
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} includedFilters={this.state.filters} excludeSearch={true} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>
        <div>
          {this.renderDashboard()}
        </div>
      </>
    )
  }
}

